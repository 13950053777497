import { MOBILEPOPUPON, MOBILEPOPUPOF } from "./ReducerCaseType";

export const reducerMobilePopup = (
  state = { isLoading: true, popupshow: false },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //     return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //     return { ...state, isLoading: false }
    // }
    case MOBILEPOPUPOF:
      return {
        ...state,
        popupshow: action.payload,
      };
    case MOBILEPOPUPON:
      return { ...state, popupshow: action.payload };
    default:
      return state;
  }
};
