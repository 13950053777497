import {
  AUTH,
  LOGOUT,
  PROFILE,
  SKT_UPDATEACCOUNT_CURRENT,
} from "./ReducerCaseType";
const AuthReducer = (
  state = {
    isLoading: true,
    authData: JSON.parse(localStorage.getItem("profile")),
  },
  action
) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.payload }));
      const data = JSON.parse(localStorage.getItem("profile"));
      return { ...state, authData: { result: data.result, token: data.token } };
    case LOGOUT:
      localStorage.clear();
      return { ...state, authData: null };
    case PROFILE:
      // const updatedData = { result: action.payload, token: state.authData.token }
      const profile = {
        ...JSON.parse(localStorage.getItem("profile")),
        result: action.payload,
      };
      localStorage.setItem("profile", JSON.stringify(profile));
      const data1 = JSON.parse(localStorage.getItem("profile"));
      return {
        ...state,
        authData: { result: data1.result, token: data1.token },
      };
    case SKT_UPDATEACCOUNT_CURRENT:
      const IdLogedin = state.authData.result._id;
      const id = action.payload._id;
      if (IdLogedin === id) {
        const profile = {
          ...JSON.parse(localStorage.getItem("profile")),
          result: { ...state.authData.result, role: action.payload.role },
        };
        localStorage.setItem("profile", JSON.stringify(profile));
        const data1 = JSON.parse(localStorage.getItem("profile"));
        return {
          ...state,
          authData: { result: data1.result, token: data1.token },
        };
      } else {
        return { ...state, authData: state.authData };
      }

    default:
      return state;
  }
};

export default AuthReducer;
