import React, { useEffect, useCallback, Suspense } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { COUNTRY } from "././redux-thunk/ReducerCaseType";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { LOGOUT } from "./API ACTION/AuthAction";
import AuthVerify from "./MultiUseComponent/AuthVerify/AuthVerify";
import { Loader } from "./MultiUseComponent/Loader/Loder";
import { SocketProvider } from "./socket";
import EditedMenu from "./componet/Dashbord/DashboardComponents/MenuSettings/MenuSettingDashboard/EditedMenuPopup/EditedMenu.jsx";
const Login = React.lazy(() => import("./componet/Login/login"));
const OTPBox = React.lazy(() =>
  import("./componet/OTPVERIFICATION/OTPVERIFICATION")
);
const ForgetPassword = React.lazy(() =>
  import("./componet/ForgetPassword/ForgetPassword")
);
const ChangePassword = React.lazy(() =>
  import("./componet/ForgetPassword/ChangePassword/ChangePassword")
);
const Signup = React.lazy(() => import("./componet/Signup/Signup"));
const Dashboard = React.lazy(() => import("./componet/Dashbord/Dashboard"));
const DashboardComponent = React.lazy(() =>
  import("./componet/Dashbord/ComponentD/DashboardComponent")
);
const StatusOrder = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/StatusOrder/StatusOrder")
);
const ViewAccount = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/ViewAccount/ViewAccount")
);
const Sales = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/Sales/sales")
);
const MenuSettings = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/MenuSettings/MenuSettings")
);
const AddAccount = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/AddAccount/AddAccount")
);
const CreateHotel = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/CreateHotel/CreateHotel")
);
const Inventry = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/Inventry/Inventry")
);
const InventryDetail = React.lazy(() =>
  import(
    "./componet/Dashbord/DashboardComponents/InventryDetail/InventryDetail"
  )
);
const Payments = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/Payments/Payments")
);
const SuccessPayment = React.lazy(() =>
  import("./MultiUseComponent/SuccessPayment/SuccessPayment")
);
const ViewAccountOrders = React.lazy(() =>
  import(
    "./componet/Dashbord/DashboardComponents/ViewAccount/ViewAccountDashboard/ViewAccOrders/ViewAccountOrders"
  )
);
const AllHotelsAccounts = React.lazy(() =>
  import(
    "./componet/Dashbord/DashboardComponents/AllHotelsAccounts/AllHotelsAccounts"
  )
);
const Account = React.lazy(() =>
  import("./componet/Dashbord/DashboardComponents/Account/Account")
);
const ReceiptSetting = React.lazy(() =>
  import(
    "./componet/Dashbord/DashboardComponents/ReceiptSetting/ReceiptSetting"
  )
);

function App() {
  const dispatch = useDispatch();
  const { authData } = useSelector((state) => state.AuthReducer);
  const { number } = useSelector((state) => state.reducerNumber);
  const user = authData;

  async function fatchCountry() {
    let URL = process.env.REACT_APP_URL;
    let res = await fetch(URL);
    let data = await res.json();
    dispatch({ type: COUNTRY, payload: data });
  }

  useEffect(() => {
    fatchCountry();
  }, []);

  const logOut = useCallback(() => {
    dispatch(LOGOUT({ type: "LOGOUT" }));
  }, [dispatch]);

  const ProtectedRoute = ({
    isAllowed,
    redirectPath = "/dashboard/home",
    children,
  }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <div className="APP">
        <SocketProvider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  redirectPath="/dashboard/home"
                  isAllowed={user == null}
                >
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                        }}
                      >
                        <Loader />
                      </div>
                    }
                  >
                    <Login />
                  </Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="dashboard"
              element={
                <ProtectedRoute redirectPath="/" isAllowed={user != null}>
                  <Suspense fallback={<div />}>
                    <Dashboard />
                  </Suspense>
                </ProtectedRoute>
              }
            >
              <Route
                path="home"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "Cashier" ||
                        user?.result?.role === "waiter" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      {user?.result?.role === "systemadmin" ? (
                        <AllHotelsAccounts />
                      ) : (
                        <DashboardComponent />
                      )}
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="statusorder"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "Cashier" ||
                        user?.result?.role === "waiter" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <StatusOrder />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="profiles"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "Cashier" ||
                        user?.result?.role === "waiter" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <CreateHotel />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="receipt"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "Cashier" ||
                        user?.result?.role === "waiter" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <ReceiptSetting />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="menusettings"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <MenuSettings />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="sales"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <Sales />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="addaccount"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "systemadmin" ||
                        user?.result?.role === "Cashier")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <AddAccount />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="viewaccount"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "systemadmin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "Cashier")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <ViewAccount />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="inventry"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <Inventry />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="inventrydetail"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <InventryDetail />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="payments"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <Payments />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard/success"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "systemadmin")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <SuccessPayment />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard/viewaccount/info"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null &&
                      (user?.result?.role === "super admin" ||
                        user?.result?.role === "systemadmin" ||
                        user?.result?.role === "Admin" ||
                        user?.result?.role === "Manger" ||
                        user?.result?.role === "Cashier")
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <ViewAccountOrders />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard/allaccounts"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null && user?.result?.role === "systemadmin"
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <AllHotelsAccounts />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard/account/:id"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      user != null && user?.result?.role === "systemadmin"
                    }
                  >
                    <Suspense fallback={<Loader />}>
                      <Account />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="/login"
              element={
                <ProtectedRoute
                  redirectPath="/dashboard/home"
                  isAllowed={user == null}
                >
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="/signup"
              element={
                <ProtectedRoute redirectPath="/" isAllowed={user == null}>
                  <Suspense fallback={<Loader />}>
                    <Signup />
                  </Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="/otp"
              element={
                <ProtectedRoute
                  redirectPath="/login"
                  isAllowed={number != null}
                >
                  <Suspense fallback={<Loader />}>
                    <OTPBox />
                  </Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="/forgetpassword"
              element={
                <ProtectedRoute redirectPath="/" isAllowed={user == null}>
                  <Suspense fallback={<Loader />}>
                    <ForgetPassword />
                  </Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="/changepassword"
              element={
                <ProtectedRoute
                  redirectPath="/forgetpassword"
                  isAllowed={number != null}
                >
                  <Suspense fallback={<Loader />}>
                    <ChangePassword />
                  </Suspense>
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<> not found</>} />
          </Routes>
        </SocketProvider>

        <AuthVerify logOut={logOut} />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
      />
    </BrowserRouter>
  );
}

export default App;
