import { CREATE_NUMBER, NUMBER, NUMBERSIGNUP } from "./ReducerCaseType";

export const reducerNumber = (
  state = { isLoading: true, number: null },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //   return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //   return { ...state, isLoading: false }
    // }
    case NUMBER:
      return {
        ...state,
        number: action.payload,
      };
    case CREATE_NUMBER:
      // const d = state.accounts;
      return { ...state, accounts: action.payload };

    default:
      return state;
  }
};

export const reducerNumberSignup = (
  state = { isLoading: true, Number: null },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //   return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //   return { ...state, isLoading: false }
    // }
    case NUMBERSIGNUP:
      return {
        ...state,
        Number: action.number,
      };

    default:
      return state;
  }
};
