import {
  ACCOUNTS,
  UPDATEACCOUNT,
  CREATE_ACCOUNT,
  DELETEACCOUNTS,
  WAITERS_ACCOUNTS,
  WAITERS_CREATE_ACCOUNT,
  WAITERS_UPDATEACCOUNT,
  WAITERS_DELETEACCOUNTS,
} from "./ReducerCaseType";

export const reducerAccounts = (
  state = { isLoading: true, accounts: [] },
  action
) => {
  switch (action.type) {
    case ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case CREATE_ACCOUNT:
      const d = state.accounts;
      return { ...state, accounts: [...d, action.payload] };

    case UPDATEACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account._id === action.payload._id ? action.payload : account
        ),
      };

    case DELETEACCOUNTS:
      return {
        ...state,
        accounts: state.accounts.filter(
          (account) => account._id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export const reducerwaitersAccounts = (
  state = { isLoading: true, waitersaccounts: [] },
  action
) => {
  switch (action.type) {
    case WAITERS_ACCOUNTS:
      localStorage.setItem("waiteracc", JSON.stringify([...action?.payload]));
      const data = JSON.parse(localStorage.getItem("waiteracc"));

      return {
        ...state,
        waitersaccounts: data,
      };
    case WAITERS_CREATE_ACCOUNT:
      const waiteraccoun = [
        ...JSON.parse(localStorage.getItem("waiteracc")),
        action.payload,
      ];
      localStorage.setItem("waiteracc", JSON.stringify(waiteraccoun));
      const data1 = JSON.parse(localStorage.getItem("waiteracc"));

      // return { ...state, menus: [...d, action.payload] };
      return { ...state, waitersaccounts: [...data1] };

    case WAITERS_UPDATEACCOUNT:
      let waiteracclocaldata = JSON.parse(localStorage.getItem("waiteracc"));
      const AcclocalData = waiteracclocaldata.map((acc) =>
        acc._id === action.payload._id ? action.payload : acc
      );
      localStorage.setItem("waiteracc", JSON.stringify(AcclocalData));
      // return { ...state, menus: state.menus.map((menu) => menu._id == action.payload._id ? action.payload : menu) }
      const WaiterAcclocalDataupdated = JSON.parse(
        localStorage.getItem("waiteracc")
      );
      return { ...state, waitersaccounts: WaiterAcclocalDataupdated };

    case WAITERS_DELETEACCOUNTS:
      let waiteracclocaldata1 = JSON.parse(localStorage.getItem("waiteracc"));
      const AcclocalDataDelete = waiteracclocaldata1.filter(
        (menu) => menu._id !== action.payload
      );
      localStorage.setItem("waiteracc", JSON.stringify(AcclocalDataDelete));
      const waiteraccDataupdatedDeleted = JSON.parse(
        localStorage.getItem("waiteracc")
      );
      return { ...state, waitersaccounts: waiteraccDataupdatedDeleted };

    default:
      return state;
  }
};
