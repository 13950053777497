import React, { useState } from "react";
import {
  MdOutlineRestaurantMenu,
  MdDelete,
} from "../../../../../../icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETEPRODUCT,
  DELETEMENU,
  MenuSettingSwiperProduct,
} from "../../../../../../API ACTION/ApiActionMenu";
import { useNavigate } from "react-router-dom";
import "./EditedMenu.css";
import MenuSettingDashboard from "../MenuSettingDashboard.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { loadimg } from "../../../../../../imges/index";
import CreateProductForm from "../CreateMenuPopup/CreateProductForm/CreateProductForm";
import "../SwapMenu/SwapMenu.css";
import { MdChevronLeft } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const EditedMenu = (props) => {
  const [DeletePro, setDeletePro] = useState([]);
  const [GetMenu, setGetMenu] = useState({});
  const [ShowForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [SelectedMenu, setSelectedMenu] = useState({});
  const { menus } = useSelector((state) => state.reducerfetchmenus);
  const { products } = useSelector((state) => state.reducerfetchproducts);

  function EditProduct(value) {
    props.setEditProductData(value);
    props.setCreateMenupopupOn(true);
    props.setEditedMenupopupOn(false);
    props.setenableReinitialize(true);
  }

  function onEnd(result , menuid) {
    const src1 = result.source.index;
    const des1 = result.destination.index;
    console.log(src1, des1)
    dispatch(
      MenuSettingSwiperProduct({ draggableId: result.draggableId, src1, des1 , menuid})
    );
  }

  function DeleteProduct(value) {
    dispatch(DELETEPRODUCT(value._id, navigate));
  }

  if (!props.currentMenu) {
    return <h1>loading</h1>;
  }
  return props.trigger ? (
    <>
      <div className="" key={props.currentMenu.menuid}>
        <div className="flexc">
          <div className="flexc">
            <MdChevronLeft
              fontSize={27}
              className="product-back-icon"
              onClick={() => props.setEditedMenupopupOn(false)}
            />

            <div className="app__Menu1-Edited-itemA-title">
              {props.currentMenu.menuname.substring(0, 30)}
            </div>
          </div>
          <div className="flexc">
            <button
              onClick={() => {
                setGetMenu({
                  name: props.currentMenu.menuname,
                  img: props.currentMenu.img,
                  id: props.currentMenu.menuid,
                  new: "old",
                });
                setShowForm(true);
                setSelectedMenu({
                  menuname: props.currentMenu.menuname,
                  menuid: props.currentMenu.menuid,
                  img: props.currentMenu.img,
                });
              }}
              className="secondary-button-ai"
            >
              <div style={{ fontSize: "30px" }}>+</div>&nbsp; Add Item
            </button>
            {/* <MdSearch
              size={44}
              color="gray"
              style={{ margin: " 5px 30px 0px 50px" }}
            /> */}
          </div>
        </div>
        <DragDropContext onDragEnd={(result) => onEnd(result, props.currentMenu.menuid)}>
          <div>
            <Droppable droppableId="1234" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="app__Menu1-Edited-itemA_items"
                >
                  {products.sort((a, b) => a.index - b.index).map((recipe, ind) => {
                    if (props.currentMenu.menuid === recipe.menuid) {
                      return (
                        <Draggable
                          key={recipe._id}
                          draggableId={recipe._id}
                          index={recipe.index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              
                            >
                              <div
                                key={ind}
                                className="app__checkout5-card-Edited"
                                onClick={() => {}}
                              >
                                <div className="app__checkout5-card-Edited-info">
                                  <div className="flexc">
                                    <div className="flex-column">
                                      <div className="app__Menu1-Edited-itemA-img">
                                        <LazyLoadImage
                                          src={recipe.img}
                                          PlaceholderSrc={loadimg}
                                          effect="blur"
                                          className="img"
                                          alt={recipe.name}
                                        />
                                      </div>
                                      <div className="app__checkout5-card-Edited-name">
                                        {recipe.name.length > 9
                                          ? `${recipe.name.substring(0, 9)}...`
                                          : recipe.name}
                                      </div>
                                      <div className="app__checkout5-card-Edited-price">
                                        Rs. {recipe.price}
                                      </div>
                                      <div className="product-card-icon">
                                        <BiEdit
                                          className="app__checkout5-card-Delete-buttons"
                                          onClick={() => EditProduct(recipe)}
                                        >
                                          Edit
                                        </BiEdit>
                                        <MdDelete
                                          className="app__checkout5-card-Delete-buttons"
                                          onClick={() => DeleteProduct(recipe)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      {ShowForm && (
        <div className="app__CreateMenu-popup">
          <br />
          <br />
          <div className="app__CreateMenu-popup_inner">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="app__CreateMenu-popup_closeicon"
              onClick={() => {
                props.setCreateMenupopupOn(false);
                props.setEditProductData(null);
                props.setEditMenuData(null);
                setShowForm(false);
                props.setenableReinitialize(false);
              }}
            />
            {ShowForm && (
              <div className="app__createmenu-product">
                <div className="app__createmenu-product-form">
                  <div
                    className={
                      props.EditMenuData != null
                        ? "app__createmenu-product-display-hide"
                        : "app__createmenu-product-display-show"
                    }
                  >
                    <CreateProductForm
                      GetMenu={GetMenu}
                      SelectedMenu={SelectedMenu}
                      EditProductData={props.EditProductData}
                      setEditProductData={props.setEditProductData}
                      setCreateMenupopupOn={props.setCreateMenupopupOn}
                      setenableReinitialize={props.setenableReinitialize}
                      enableReinitialize={props.enableReinitialize}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  ) : (
    ""
  );
};

export default EditedMenu;
