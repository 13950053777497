import {
  START_LOADING_InventryDetail,
  END_LOADING_InventryDetail,
  InventryDetail_USERPRODUCTS,
  CREATE_InventryDetail_PRODUCT,
  UPDATE_InventryDetail_PRODUCT,
  DELETE_InventryDetail_PRODUCT,
} from "./ReducerCaseType";

export const reducerfetchInventryDetailproducts = (
  state = { isLoading: true, inventrydetailproducts: [] },
  action
) => {
  switch (action.type) {
    case START_LOADING_InventryDetail: {
      return { ...state, isLoading: true };
    }
    case END_LOADING_InventryDetail: {
      return { ...state, isLoading: false };
    }

    case InventryDetail_USERPRODUCTS:
      return {
        ...state,
        inventrydetailproducts: action.payload.reverse(),
      };

    case CREATE_InventryDetail_PRODUCT:
      const d = state.inventrydetailproducts;

      return { ...state, inventrydetailproducts: [...d, action.payload] };

    case UPDATE_InventryDetail_PRODUCT:
      return {
        ...state,
        inventrydetailproducts: state.inventrydetailproducts.map(
          (inventrydetailproduct) =>
            inventrydetailproduct._id === action.payload._id
              ? action.payload
              : inventrydetailproduct
        ),
      };

    case DELETE_InventryDetail_PRODUCT:
      return {
        ...state,
        inventrydetailproducts: state.inventrydetailproducts.filter(
          (inventrydetailproduct) =>
            inventrydetailproduct._id !== action.payload
        ),
      };

    default:
      return state;
  }
};
