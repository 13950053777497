import {
  INCREMENT_CART,
  DECREMENT_CART,
  CHECKOUT_INCREMENT_CART,
  DECREMENT_CART_CHECKOUT,
  DELETE_CART_CHECKOUT,
  BILL_ORDERS,
  CLEAR_CHECKOUT,
  EditOrder,
  UPDATEBILLORDER,
  MENUSETTINGSWIPING,
  MENUPRODUCTSWIPING,
  USERMENUS,
  CREATE_USERMENUS,
  CREATE_PRODUCT,
  USERPRODUCTSUPDATES,
  SEARCH_CART_ORDER,
  USERPRODUCTS,
  START_LOADING,
  END_LOADING,
  UPDATE_MENU,
  DELETE_MENU,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ITEMS,
  UPDATE_PRODUCT_ITEMS_FAIL,
  CART_ORDERS,
  UPDATECARTORDER,
  CREATE_CART_ORDERS,
  DELETECARTORDER,
  CREATE_CART_ORDERS_PRINT,
  CARTS_ADDS_QUANTITYMENU_FRONT,
  CARTS_ADDS_PRICEMENU_FRONT,
  DELETE_CARTS_ITEM_FRONT,
  CART_ORDERS_PAGI_SUCCESS,
} from "./ReducerCaseType";
import { toast } from "react-toastify";
export const Menureducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const reducercheckoutincre = (state = [], action) => {
  switch (action.type) {
    case BILL_ORDERS:
      return [...state, action.payload];

    case UPDATEBILLORDER:
      return state.map((items) => {
        if (items.id === action.payload.id) {
          return {
            ...items,
            CustmerName: action.payload.CustmerName,
            Discount: action.payload.Discount,
            FinalBill: action.payload.FinalBill,
            ItemCount: action.payload.ItemCount,
            paymentItems: action.payload.paymentItems,
            totalBill: action.payload.totalBill,
            Edit: action.payload.Edit,
          };
        } else {
          return items;
        }
      });

    default:
      return state;
  }
};

export const reducerfetchmenus = (
  state = { isLoading: true, menus: [] },
  action
) => {
  switch (action.type) {
    case START_LOADING: {
      return { ...state, isLoading: true };
    }
    case END_LOADING: {
      return { ...state, isLoading: false };
    }

    case USERMENUS:
      // console.log({ ...action?.payload })
      localStorage.setItem("usermenu", JSON.stringify([...action?.payload]));
      const data = JSON.parse(localStorage.getItem("usermenu"));
      return {
        ...state,
        menus: data,
      };

    case MENUSETTINGSWIPING:
      return {
        ...state,
        menus: action.payload,
      };

    case CREATE_USERMENUS:
      // const d = state.menus;
      const usermenu = [
        ...JSON.parse(localStorage.getItem("usermenu")),
        action.payload,
      ];
      localStorage.setItem("usermenu", JSON.stringify(usermenu));
      const data1 = JSON.parse(localStorage.getItem("usermenu"));

      // return { ...state, menus: [...d, action.payload] };
      return { ...state, menus: [...data1] };

    case UPDATE_MENU:
      let usermenulocaldata = JSON.parse(localStorage.getItem("usermenu"));
      const MenulocalData = usermenulocaldata.map((menu) =>
        menu._id === action.payload._id ? action.payload : menu
      );
      localStorage.setItem("usermenu", JSON.stringify(MenulocalData));
      // return { ...state, menus: state.menus.map((menu) => menu._id == action.payload._id ? action.payload : menu) }
      const MenulocalDataupdated = JSON.parse(localStorage.getItem("usermenu"));
      return { ...state, menus: MenulocalDataupdated };

    case DELETE_MENU:
      let usermenulocaldataDelete = JSON.parse(
        localStorage.getItem("usermenu")
      );
      const MenulocalDataDelete = usermenulocaldataDelete.filter(
        (menu) => menu._id !== action.payload
      );
      localStorage.setItem("usermenu", JSON.stringify(MenulocalDataDelete));
      const MenulocalDataupdatedDeleted = JSON.parse(
        localStorage.getItem("usermenu")
      );
      return { ...state, menus: MenulocalDataupdatedDeleted };
    // return { ...state, menus: state.menus.filter((menu) => menu._id !== action.payload) };

    default:
      return state;
  }
};

export const reducerfetchproducts = (
  state = { isLoading: true, products: [] },
  action
) => {
  switch (action.type) {
    case USERPRODUCTS:
      localStorage.setItem("userproduct", JSON.stringify([...action?.payload]));
      // const data = JSON.parse(localStorage.getItem('userproduct'));
      console.log(action.payload);
      return {
        ...state,
        products: action.payload,
      };


      case MENUPRODUCTSWIPING:
        return {
          ...state,
          products: action.payload,
        };
  
    case USERPRODUCTSUPDATES:
      // console.log({ ...state, products: state.products.map((product) => {
      //   // product._id
      //   const findPro = action.payload.find((item)=>{
      //     console.log(item)
      //   return item._id ==  product._id ;
      //   })
      //   console.log(findPro)
      //   console.log({...product,quantitycheck:findPro.quantitycheck})
      //   return {...product,quantitycheck:findPro.quantitycheck}
      // }
      // )})

      return {
        ...state,
        products: state.products.map((product) => {
          const findPro = action.payload.find((item) => {
            return item._id === product._id;
          });
          return { ...product, quantitycheck: findPro.quantitycheck };
        }),
      };

    case CREATE_PRODUCT:
      const d = state.products;

      return { ...state, products: [...d, action.payload] };

    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product._id === action.payload._id ? action.payload : product
        ),
      };

    case UPDATE_PRODUCT_ITEMS:
      return {
        ...state,
        products: state.products.map((product) => {
          //   action.payload.map((item)=>{
          //   if(product._id == item._id ){
          //     return item;
          //   }
          // }
          //   )

          const datafind = action.payload.find(
            (item) => item._id === product._id
          );

          if (datafind === undefined) {
            return { ...product, quantity: 0 };
          } else if (datafind !== undefined) {
            if (product._id === datafind._id) {
              //  console.log(product)
              //  console.log(datafind)
              //  console.log({changingquantity:datafind.changingquantity,createdAt:datafind.createdAt,creator:datafind.creator,img:product.img,
              //   inventryquantity:datafind.inventryquantity ,menuid:datafind.menuid,menuname:datafind.menuname,name:datafind.name,price:datafind.price,quantity:datafind.quantity,_id:datafind._id
              // });
              //  return datafind
              // return {changingquantity:product.changingquantity,createdAt:product.createdAt,creator:product.creator,img:product.img,
              //   inventryquantity:product.inventryquantity ,menuid:datafind.menuid,menuname:datafind.menuname,name:datafind.name,price:datafind.price,quantity:datafind.quantity,_id:product._id
              // }
              return {
                changingquantity: datafind.changingquantity,
                createdAt: datafind.createdAt,
                creator: datafind.creator,
                img: product.img,
                quantitycheck: datafind.quantitycheck,
                quantitycheckType: datafind.quantitycheckType,
                inventryquantity: datafind.inventryquantity,
                menuid: datafind.menuid,
                menuname: datafind.menuname,
                name: datafind.name,
                price: datafind.price,
                quantity: datafind.quantity,
                _id: datafind._id,
                TaxRate: datafind.TaxRate,
                TaxCharged: datafind.TaxCharged,
                InvoiceType: datafind.InvoiceType,
                PCTCode: datafind.PCTCode,
              };
            }
          }
        }),
      };

    case UPDATE_PRODUCT_ITEMS_FAIL:
      return {
        ...state,
        products: state.products.map((product) => {
          const datafind = action.payload.find(
            (item) => item._id === product._id
          );

          if (datafind === undefined) {
            return product;
          } else if (datafind !== undefined) {
            if (product._id === datafind._id) {
              return {
                changingquantity: datafind.changingquantity,
                createdAt: datafind.createdAt,
                creator: datafind.creator,
                img: product.img,
                quantitycheck: datafind.quantitycheck,
                quantitycheckType: datafind.quantitycheckType,
                inventryquantity: datafind.inventryquantity,
                menuid: datafind.menuid,
                menuname: datafind.menuname,
                name: datafind.name,
                price: datafind.price,
                quantity: product.quantity,
                _id: datafind._id,
                TaxRate: datafind.TaxRate,
                TaxCharged: datafind.TaxCharged,
                InvoiceType: datafind.InvoiceType,
                PCTCode: datafind.PCTCode,
              };
            }
          }
        }),
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product._id !== action.payload
        ),
      };

    case INCREMENT_CART:
      // console.log(items)
      return {
        ...state,
        products: state.products.map((items) => {
          if (
            items.menuid === action.payload.RecipeMenuid &&
            items._id === action.payload.RecipeFull._id
          ) {
            // console.log(items)
            // return { ...items, inventryquantity: items.inventryquantity - 1 }
            if (action.payload.RecipeFull.quantitycheck === false) {
              return items;
            } else if (
              action.payload.RecipeFull.quantitycheck === true &&
              items.inventryquantity <= 0
            ) {
              return items;
            } else if (
              items.inventryquantity >= 1 &&
              items.inventryquantity <= items.changingquantity
            ) {
              return { ...items, inventryquantity: items.inventryquantity - 1 };
            }
          } else {
            return items;
          }
        }),
      };

    case DECREMENT_CART:
      return {
        ...state,
        products: state.products.map((items) => {
          if (
            items.menuid === action.payload.RecipeMenuid &&
            items._id === action.payload.RecipeFull._id
          ) {
            // return { ...items, quantity: items.quantity - (items.quantity > 0 ? 1 : 0) }
            // return { ...items, inventryquantity: items.inventryquantity + 1 }

            if (action.payload.RecipeFull.quantitycheck === false) {
              return items;
            } else if (
              items.inventryquantity < items.changingquantity &&
              action.payload.RecipeFull.quantitycheck === true
            ) {
              return { ...items, inventryquantity: items.inventryquantity + 1 };
              // return items;
            } else {
              toast.error("👍 You add Element and then click on Decrement!", {
                position: "top-center",
                autoClose: 50,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              return items;
            }
            // }else if(items.inventryquantity > 0 && items.inventryquantity <= items.changingquantity){
            //   return { ...items, inventryquantity: items.inventryquantity + 1 }
            // }
          } else {
            return items;
          }
        }),
      };

    case CARTS_ADDS_QUANTITYMENU_FRONT:
      return {
        ...state,
        products: state.products.map((items) => {
          if (
            items.menuid === action.payload.RecipeMenuid &&
            items._id === action.payload.RecipeFull._id
          ) {
            if (action.payload.RecipeFull.quantitycheck === false) {
              return items;
            } else if (items.inventryquantity < 0) {
              return items;
            } else if (
              items.inventryquantity >= 0 &&
              items.changingquantity >= 1 &&
              items.inventryquantity <= items.changingquantity &&
              Number(action.payload.QuantityMeny) >= 0 &&
              Number(action.payload.QuantityMeny) <= items.changingquantity
            ) {
              return {
                ...items,
                inventryquantity:
                  items.changingquantity - Number(action.payload.QuantityMeny),
              };
            } else {
              return items;
            }
          } else {
            return items;
          }
        }),
      };

    case CARTS_ADDS_PRICEMENU_FRONT:
      return {
        ...state,
        products: state.products.map((items) => {
          if (
            items.menuid === action.payload.RecipeMenuid &&
            items._id === action.payload.RecipeFull._id
          ) {
            const M = Number(action.payload.QuantityMeny);
            const QUANTITY = M / items.price;

            if (
              action.payload.RecipeFull.quantitycheck === false &&
              QUANTITY >= 0
            ) {
              return items;
            } else if (items.inventryquantity < 0) {
              return items;
            } else if (
              items.inventryquantity >= 0 &&
              items.changingquantity >= 1 &&
              items.inventryquantity <= items.changingquantity &&
              QUANTITY >= 0 &&
              QUANTITY <= items.changingquantity &&
              action.payload.RecipeFull.quantitycheck === true
            ) {
              return {
                ...items,
                inventryquantity: items.changingquantity - QUANTITY,
              };
            } else {
              return items;
            }
          } else {
            return items;
          }
        }),
      };

    case DELETE_CARTS_ITEM_FRONT:
      return {
        ...state,
        products: state.products.map((items) => {
          if (items._id === action.payload.RecipeFull._id) {
            // return { ...items, quantity: items.quantity, inventryquantity: action.payload.RecipeFull.inventryquantity, changingquantity: action.payload.RecipeFull.changingquantity }
            return {
              ...items,
              quantity: 0,
              inventryquantity:
                items.inventryquantity +
                Number(action.payload.RecipeFull.quantity),
              changingquantity: items.changingquantity,
            };
          } else {
            return items;
          }
        }),
      };

    case CHECKOUT_INCREMENT_CART:
      return {
        ...state,
        products: state.products.map((items) => {
          if (items._id === action.payload.RecipeFull._id) {
            return { ...items, quantity: items.quantity + 1 };
          } else {
            return items;
          }
        }),
      };

    case DECREMENT_CART_CHECKOUT:
      return {
        ...state,
        products: state.products.map((items) => {
          if (items._id === action.payload.RecipeFull._id) {
            return {
              ...items,
              quantity: items.quantity - (items.quantity > 0 ? 1 : 0),
            };
          } else {
            return items;
          }
        }),
      };

    case EditOrder:
      return {
        ...state,
        Username: action.payload.CustmerName,
        Discount: action.payload.Discount,
        Billid: action.payload._id,
        OrderReciverid: action.payload.OrderReciverid,
        OrderReciverName: action.payload.OrderReciverName,
        products: state.products.map((items) => {
          let a = action.payload.cartItems.find((e) => e._id === items._id);

          if (a !== undefined) {
            // return { ...items, quantity: a.quantity }
            return {
              ...items,
              quantity: a.quantity,
              changingquantity: items.changingquantity + a.quantity,
            };
          } else if (a === undefined) {
            return items;
          }
        }),
      };

    case DELETE_CART_CHECKOUT:
      return {
        ...state,
        products: state.products.map((items) => {
          if (items._id === action.payload.RecipeFull._id) {
            return { ...items, quantity: 0 };
          } else {
            return items;
          }
        }),
      };

    case CLEAR_CHECKOUT:
      return {
        ...state,
        products: state.products.map((items) => {
          return { ...items, quantity: 0 };
        }),
      };

    default:
      return state;
  }
};

export const reducercart = (
  state = { isLoading: true, carts: [], currentPage: 1, totalPages: 1 },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //   return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //   return { ...state, isLoading: false }
    // }

    case CART_ORDERS:
      return {
        ...state,
        carts: [...action.payload.carts],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };

    case CART_ORDERS_PAGI_SUCCESS:
      return {
        ...state,
        carts: [...state.carts, ...action.payload.carts],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    // return {
    //   ...state,
    //   carts: action.payload.reverse(),
    // };

    case CREATE_CART_ORDERS:
      const d = state.carts;

      return { ...state, carts: [action.payload, ...d] };

    case UPDATECARTORDER:
      return {
        ...state,
        carts: state.carts.map((cart) =>
          cart._id === action.payload._id ? action.payload : cart
        ),
      };

    case DELETECARTORDER:
      return {
        ...state,
        carts: state.carts.filter((cart) => cart._id !== action.payload),
      };

    case SEARCH_CART_ORDER:
      const s = state.carts;
      const existFind = s.filter((item) => item._id !== action.payload._id);

      return { ...state, carts: [action.payload, ...existFind] };
    default:
      return state;
  }
};

// CREATE_CART_ORDERS_PRINT

export const reducerprint = (
  state = { isLoading: true, print: [] },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //   return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //   return { ...state, isLoading: false }
    // }

    case CREATE_CART_ORDERS_PRINT:
      return { ...state, print: action.payload };

    default:
      return state;
  }
};
