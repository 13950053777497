import {
  SUBCRIPTIONS,
  CREATE_SUBCRIPTION,
  UPDATESUBCRIPTION,
  DELETESUBCRIPTION,
  START_LOADING_SUBCRIPTION,
  END_LOADING_SUBCRIPTION,
} from "./ReducerCaseType";
export const reducerSubcriptions = (
  state = { isLoading: true, subcriptions: [] },
  action
) => {
  switch (action.type) {
    case START_LOADING_SUBCRIPTION: {
      return { ...state, isLoading: true };
    }
    case END_LOADING_SUBCRIPTION: {
      return { ...state, isLoading: false };
    }

    case SUBCRIPTIONS:
      return {
        ...state,
        subcriptions: action.payload,
      };
    // case CREATE_SUBCRIPTION:
    //     const d = state.subcriptions;
    //     return { ...state, subcriptions: [...d, action.payload] };
    case CREATE_SUBCRIPTION:
      const existingIndex = state.subcriptions.findIndex(
        (sub) => sub._id === action.payload._id
      );
      if (existingIndex !== -1) {
        // Replace the existing subscription with the new one
        return {
          ...state,
          subcriptions: state.subcriptions.map((sub, index) =>
            index === existingIndex ? action.payload : sub
          ),
        };
      } else {
        // Add the new subscription if it doesn't exist
        return {
          ...state,
          subcriptions: [...state.subcriptions, action.payload],
        };
      }

    case UPDATESUBCRIPTION:
      return {
        ...state,
        subcriptions: state.subcriptions.map((sub) =>
          sub._id === action.payload._id ? action.payload : sub
        ),
      };

    case DELETESUBCRIPTION:
      return {
        ...state,
        subcriptions: state.subcriptions.filter(
          (sub) => sub._id !== action.payload
        ),
      };

    default:
      return state;
  }
};
