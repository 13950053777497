import axios from "axios";

const API =axios.create({baseURL:process.env.REACT_APP_HITURL})


API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.authorization = `love ${JSON.parse(localStorage.getItem('profile')).token}`
    };
    return req;
})


export const fetchPosts = () => API.get('/posts');
// export const FindPosts = (SearchQuery)=> API.get(`/posts/find?search=${SearchQuery.search || 'none'}&tags=${SearchQuery.tags || 'none'}`);
// export const FatchSelectedPostById =(id)=>API.get(`/posts/${id}`)
export const createPost = (Data) => API.post('/posts', Data);
export const UpdatePost = (id, updatedpost) => API.patch(`/posts/${id}`, updatedpost);
export const Deletepost = (id) => API.delete(`/posts/${id}`);
export const SWAPPOST = (swipeMenu, id) => API.patch(`/posts/${id}/swap`, swipeMenu);
export const SWAPPOSTPRODUCT = (swipeMenu, id) => API.patch(`/product/${id}/swap`, swipeMenu);

export const fetchProducts = () => API.get('/product');
export const createProduct = (Data) => API.post('/product', Data);
export const UpdateProduct = (id, updatedpost) => API.patch(`/product/${id}`, updatedpost);
export const DeleteProduct = (id) => API.delete(`/product/${id}`);
export const DeleteProducts = (id, DeletePro) => API.post(`/product/${id}/deletepro`, DeletePro);

export const fetchExtraProducts = () => API.get('/extraproduct');
export const createExtraProduct = (Data) => API.post('/extraproduct', Data);
export const UpdateExtraProduct = (id, updatedpost) => API.patch(`/extraproduct/${id}`, updatedpost);
export const DeleteExtraProduct = (id) => API.delete(`/extraproduct/${id}`);
export const DeleteExtraProducts = (id, DeletePro) => API.post(`/extraproduct/${id}/deletepro`, DeletePro);

export const fetchInventryDetailProducts = () => API.get('/inventrydetailproduct');
export const createInventryDetailProduct = (Data) => API.post('/inventrydetailproduct', Data);
export const clearInventryDetailProduct = (productid) => API.post('/inventrydetailproduct/clear', {productid:productid});
export const UpdateInventryDetailProduct = (id, updatedpost) => API.patch(`/inventrydetailproduct/${id}`, updatedpost);
export const DeleteInventryDetailProduct = (id) => API.delete(`/inventrydetailproduct/${id}`);
export const DeleteInventryDetailProducts = (id, DeletePro) => API.post(`/inventrydetailproduct/${id}/deletepro`, DeletePro);
export const inventryquantityOnOff = (id) => API.patch(`/inventrydetailproduct/quantity/${id}`);
export const ALLSinventryquantityOnOff = (inventryQunatityType) => API.post('/inventrydetailproduct/allquantitytype',{inventryQunatityType:inventryQunatityType});

export const CreateCart = (Order) => API.post('/cart', Order)

export const OrderABI = (PRADATA) => axios.post('http://localhost:8524/api/IMSFiscal/GetInvoiceNumberByModel',PRADATA)

// export const FetchCarts = () => API.get('/cart');
export const FetchCarts = (page) => API.get(`/cart?page=${page}`);

export const FetchCartsWITHTIME = (TIME) => API.post('/cart/time', { time: TIME });
export const FetchCartsWITHTimeMonth = (TIME) => API.post('/cart/time/month', { time: TIME });
export const FetchCartsWITHTimeYear = (TIME) => API.post('/cart/time/year', { time: TIME });


export const FetchCartsWITHTIMECustom = (TIME) => API.post('/cart/timecustom', TIME);
export const FetchAccountCartsWITHTIMECustom = (TIME) => API.post('/cart/accounttimecustom', TIME);
export const FetchCartsWITHTIMECustomAdmin = (TIME) => API.post('/cart/admintimecustom', TIME);


export const UpdateCart = (id, updatedcart) => API.patch(`/cart/${id}`, updatedcart);
export const DeleteOrder = (id) => API.delete(`/cart/${id}`);
export const CartDeleteFinds = () => API.get('/cart/deletedcarts/');
export const DeleteOrderAdmin = (id) => API.delete(`/cart/admin/${id}`);
// export const CartFinds = (SearchQuery)=> API.get(`/cart/find?search=${SearchQuery.search || 'none'}`);
export const CartFinds = (id) => API.get(`/cart/find/${id}`);
export const DeleteOrderAndUpdateProduct = (CART) =>  API.post(`/cart/updateproductanddeleteorder/id`, CART);
export const UpdateOrderByPraID = (InvoiceNumber,CART) => API.patch(`/cart/updateorderpraid/${InvoiceNumber}`, CART);
export const GETCARTSEARCH = (Searchdata) => API.post('/cart/search', Searchdata);
export const signUp = (SignupData) => API.post('/users/signup', SignupData);
export const logIn = (loginData) => API.post('/users/login', loginData);
export const USERUPDATE = (userdata, id) => API.patch(`/users/${id}/useredit`, userdata);
export const HOTELUPDATE = (hoteldata, id) => API.patch(`/users/${id}/hoteledit`, hoteldata);
export const VerifyUsers = (otp) => API.post('/users/signup/verify', otp);
export const forgetUser = (number) => API.post("/users/signup/forget", number);
export const ChangeUserPassword = (otp) => API.post("/users/signup/changepassword", otp);
export const CurrentUsermenustylechanged = (data) => API.post('/users/menustyletype',{MenuStyleType:data.MenuStyleType,ID:data.ID});
export const CurrentUserAccountLocked = (user) => API.post('/users/subcriptionexpire',{user:user._id});
export const CONECTPRA = (PRADATA) => API.post("/users/pra",PRADATA);
export const DISCONECTPRA = (userId) => API.post("/users/pra/disconnect",userId);



export const AddAccount = (createaccountdata) => API.post('/addaccounts', createaccountdata);
export const GetAccounts = () => API.get('/addaccounts');
export const GetwaiterAcconts = () => API.get('/addaccounts/allwaiters');
export const DeleteAccount = (id) => API.delete(`/addaccounts/${id}`);
export const updateRole = (accountdata) => API.post('/addaccounts/updaterole', accountdata);


export const CREATESUBCRIPTIONITEM = (data) => API.post('/subcription', data);
export const FETCHSUBCRIPTIONS = () => API.get('/subcription');
export const EDITSUBCRIPTIONITEM = (id,data) => API.patch(`/subcription/${id}/edit`,data);
export const DELETESUBCRIPTIONITEM = (id) => API.delete(`/subcription/${id}`);
export const FETCHSUBCRIPTIONINVOICES = () => API.get('/subcription/allinvoices');



export const CountryData = () => axios.get('https://restcountries.com/v3.1/all');

export const ADDBUYERSALE = (data) => API.post('/buyeraccounts/sale', data);
export const ADDBUYERRECEIVED = (data) => API.post('/buyeraccounts/received', data);
export const FETCHBUYERDATACUSTOMTIME = (TIME) => API.post('/buyeraccounts/timecustom', TIME);
export const DELETEBUYERSALE = (id) => API.delete(`/buyeraccounts/sale/${id}`);
export const DELETEBUYERRECEIVE = (id) => API.delete(`/buyeraccounts/received/${id}`);
export const DELETEBUYERACCOUNT = (id) => API.delete(`/buyeraccounts/buyeraccount/${id}`);


export const GETALLACCOUNTS = (page) => API.get(`/users/allaccounts?page=${page}`);
export const GETACCOUNT = (id) => API.get(`/users/account/${id}`);
export const GETACCOUNTSEARCH = (Searchdata) => API.post('/users/account/search', Searchdata);
export const AdminFreezeAccount = (User) => API.post('/users/systemadminfreeze',User);
export const AdminUnFreezeAccount = (User) => API.post('/users/systemadminunfreeze',User);
export const AdminDeleteAccount = (User) => API.post('/users/systemadmindelete',User);
export const USERREFRESHONSOCKET  = ()=> API.post('/users/allaccountsreload')
export const CurrentUserAccountAddSubcribtion = (User) => API.post('/users/addsubcriptionadmin',User);
export const CurrentUserAccountDeleteSubcribtion = (User) => API.post('/users/deletesubcriptionadmin',User);
export const CurrentUserAccountExtendSubcription = (User) => API.post('/users/extendsubcriptionadmin',User);

export default API;


// schema or system updation HITS
// export const UpdateUserSchema = () => API.post("/users/schemaupdate");
// export const ChangeSubcriptionPeriod = ()=> API.post('/users/ChangeSubcriptionPeriod')