import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import Vector from "../../../../../../../imges/Vector.svg";
import { useDropzone } from "react-dropzone";
import { Form, Formik } from "formik";
import { number, object, string } from "yup";
import "./CreateProductForm.css";
import "../CreateMenuForm/CreateMenuForm.css";
import { toast } from "react-toastify";
import { MdError } from "../../../../../../../icons/Icons";
import { useDispatch } from "react-redux";
import {
  CreateProductItem,
  EditProductItem,
} from "../../../../../../../API ACTION/ApiActionMenu";
import { useNavigate } from "react-router-dom";
const images = require.context(
  "../../../../../../../products",
  false,
  /\.(png|jpe?g|svg)$/
);
const predefinedImages = images.keys().map((key) => images(key));

const CreateProductForm = ({
  SelectedMenu,
  setEditProductData,
  EditProductData,
  setCreateMenupopupOn,
  setenableReinitialize,
  enableReinitialize,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [errorName, seterrorName] = useState(false);
  let [productPrice, setproductPrice] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [files, setfiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      acceptedFiles.map(async (file) => {
        const CompImg = await handleImageUpload(file);
        const base64 = await convertBase64(CompImg);
        setfiles([{ preview: base64 }]);
      });
    },

    noClick: (acceptedFiles) => {
      acceptedFiles.map(async (file) => {
        const CompImg = await handleImageUpload(file);
        const base64 = await convertBase64(CompImg);
        setfiles([{ preview: base64 }]);
      });
    },
  });
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function handleImageUpload(imageFile) {
    const options = {
      maxSizeMB: 0.02,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      toast.error("⚠️ Image compression failed. Please try again.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  const handleImageSelect = async (imageUrl) => {
    if (!imageUrl) return;
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "predefined-image.jpg", {
        type: blob.type,
      });
      const compressedFile = await handleImageUpload(file);
      const base64 = await convertBase64(compressedFile);
      setfiles([{ preview: base64 }]);
      setSelectedImage(imageUrl); // Update selected image
    } catch (error) {
      toast.error(
        "⚠️ Failed to process the predefined image. Please try again.",
        {
          position: "top-center",
          autoClose: 1000,
        }
      );
    }
  };
  const [INITIALVALUES, setINITIALVALUES] = useState({ name: "", number: "" });
  useEffect(() => {
    if (EditProductData != null) {
      setfiles([{ preview: EditProductData.img }]);
      setINITIALVALUES({
        name: EditProductData.name,
        number: EditProductData.price,
      });
    }
  }, [EditProductData]);

  function handles(values) {
    if (EditProductData != null) {
      const a = { ...values };
      const fileImg = files?.map((file) => file.preview);
      const imgAddress = fileImg[0];
      dispatch(
        EditProductItem(
          {
            name: a.name,
            price: parseInt(a.number),
            img: imgAddress,
            id: EditProductData._id,
            menuname: SelectedMenu.menuname,
            menuid: SelectedMenu.menuid,
          },
          navigate
        )
      );
      setfiles([]);
      setEditProductData(null);
      setCreateMenupopupOn(false);
      setenableReinitialize(false);
    } else {
      const a = { ...values };
      const fileImg = files?.map((file) => file.preview);
      const imgAddress = fileImg[0];
      if (imgAddress === undefined) {
        dispatch(
          CreateProductItem(
            {
              name: a.name,
              price: parseInt(a.number),
              img: SelectedMenu.img,
              menuname: SelectedMenu.menuname,
              menuid: SelectedMenu.menuid,
            },
            navigate
          )
        );
        setfiles([]);
      } else {
        dispatch(
          CreateProductItem(
            {
              name: a.name,
              price: parseInt(a.number),
              img: imgAddress,
              menuname: SelectedMenu.menuname,
              menuid: SelectedMenu.menuid,
            },
            navigate
          )
        );
        setfiles([]);
      }
    }
  }

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={INITIALVALUES}
      validationSchema={object({
        name: string()
          .required("Please enter a name.")
          .min(3, "Name is too short")
          .max(40, "Name is too long"),
        number: number().required("Please enter price").min(1),
      })}
      onSubmit={(values, formikHelpers) => {
        formikHelpers.resetForm();
        handles(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            className="app__signup-page_form-content-form"
          >
            <div className="app__signup-page_form-content-title">
              {" "}
              {EditProductData != null ? "Edit Item" : "Add Item"}
            </div>
            <div
              className={
                !errorName
                  ? "app__signup-page_form-content-label-m"
                  : "app__signup-page_form-content-label-red-m"
              }
            >
              {" "}
              Image <span className="mark">*</span>{" "}
            </div>
            <div className="App__Box">
              <div {...getRootProps()} className="primary-input-box">
                <input {...getInputProps()} />
                <div>
                  <img
                    src={Vector}
                    onClick={open}
                    alt="Vector Icon"
                    className="vector-svg"
                  />
                </div>
                <p>Upload or drag & drop</p>
              </div>
              <div className="App__Box-img">
                {files.map((file, ind) => (
                  <div key={file.name}>
                    <div>
                      <img
                        src={file.preview}
                        alt="Preview"
                        style={{ height: "170px", width: "320px",cursor: "pointer" }}
                        onClick={open}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className="photo-p">
              Please ensure your photo is no longer than 1MB!
            </p>
            <div className="predefined-images-section">
              <label
                htmlFor="predefined-images-dropdown"
                className="app__signup-page_form-content-label-m"
              ></label>
              <div className="custom-dropdown">
                <button
                  type="button"
                  className="custom-dropdown-button"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    "Select an Image"
                  )}
                </button>
                {dropdownOpen && (
                  <div className="custom-dropdown-options">
                    {predefinedImages.map((image, index) => (
                      <div
                        key={index}
                        className="custom-dropdown-option"
                        onClick={() => {
                          handleImageSelect(image);
                          setDropdownOpen(false);
                        }}
                      >
                        <img
                          src={image}
                          alt={`Predefined ${index}`}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "5px",
                          }}
                        />
                        <span className="image-label">Image {index + 1}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                !errorName
                  ? "app__signup-page_form-content-label-m"
                  : "app__signup-page_form-content-label-red-m"
              }
            >
              Item Name <span className="mark">*</span>
            </div>
            <div
              className={
                !errorName
                  ? "app__signup-page_form-content-input-name "
                  : "app__signup-page_form-content-input-name_Red "
              }
            >
              <div className="app__signup-page_form-content-input-passwordicon"></div>
              <input
                label="Name"
                type="name"
                name="name"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.name}
                className="app__signup-page_form-content-input-name-input"
              />
            </div>
            <div className="app__signup-page_form-content-input-name-hidetag">
              <div
                className={
                  !errorName
                    ? "app__signup-page_form-content-input-name-hide"
                    : ""
                }
              >
                <MdError />
                &nbsp;
              </div>
              {errors.name && touched.name && errors.name
                ? seterrorName(true)
                : seterrorName(false)}
              {errors.name && touched.name && errors.name}
            </div>

            <div
              className={
                !errorName
                  ? "app__signup-page_form-content-label-m"
                  : "app__signup-page_form-content-label-red-m"
              }
            >
              Price <span className="mark">*</span>
            </div>
            <div
              className={
                !errorName
                  ? "app__signup-page_form-content-input-name "
                  : "app__signup-page_form-content-input-name_Red "
              }
            >
              <div className="app__signup-page_form-content-input-passwordicon"></div>
              <input
                label="Name"
                type="name"
                name="number"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.number}
                className="app__signup-page_form-content-input-name-input"
              />
            </div>
            <div className="app__signup-page_form-content-input-name-hidetag">
              <div
                className={
                  !productPrice
                    ? "app__signup-page_form-content-input-name-hide"
                    : ""
                }
              >
                <MdError />
                &nbsp;
              </div>
              {errors.number && touched.number && errors.number
                ? setproductPrice(true)
                : setproductPrice(false)}
              {errors.number && touched.number && errors.number}
            </div>

            <div className="primary-button flex__center">
              {EditProductData != null ? (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="custom__button "
                >
                  Save & Continue
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="custom__button "
                >
                  Save & Continue
                </button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateProductForm;
