import {
  INCREMENT_CART,
  DECREMENT_CART,
  START_LOADING,
  END_LOADING,
  START_LOADING1,
  END_LOADING1,
  END_LOADING1_CUSTOM,
  START_LOADING1_CUSTOM,
  CHECKOUT_INCREMENT_CART,
  DECREMENT_CART_CHECKOUT,
  CLEAR_CHECKOUT,
  SEARCH_CART_ORDER,
  DELETE_CART_CHECKOUT,
  EditOrder,
  MENUSETTINGSWIPING,
  MENUPRODUCTSWIPING,
  USERMENUS,
  CREATE_USERMENUS,
  USERPRODUCTS,
  CREATE_PRODUCT,
  UPDATE_MENU,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CART_ORDERS,
  UPDATECARTORDER,
  CREATE_CART_ORDERS,
  DELETECARTORDER,
  UPDATE_PRODUCT_ITEMS,
  UPDATE_PRODUCT_ITEMS_FAIL,
  CREATE_CART_ORDERS_PRINT,
  DELETE_MENU,
  DAYCARTSDATA,
  MONTHCARTSDATA,
  YEARCARTSDATA,
  CUSTOMCARTSDATA,
  WAITERS_ACCOUNTS,
  CUSTOMCARTSACCOUNTDATA,
  SKT_CUSTOMCART_DELETE,
  SKT_CUSTOMCART_UPDATE,
  CART_ORDERS_PAGI_SUCCESS,
} from "../redux-thunk/ReducerCaseType";

import {
  createPost,
  fetchPosts,
  UpdatePost,
  SWAPPOST,
  createProduct,
  fetchProducts,
  UpdateProduct,
  DeleteProduct,
  Deletepost,
  DeleteProducts,
  CreateCart,
  FetchCarts,
  UpdateCart,
  DeleteOrder,
  FetchCartsWITHTIME,
  FetchCartsWITHTimeMonth,
  FetchCartsWITHTimeYear,
  CartFinds,
  FetchCartsWITHTIMECustom,
  FetchAccountCartsWITHTIMECustom,
  OrderABI,
  DeleteOrderAndUpdateProduct,
  UpdateOrderByPraID,
  GetwaiterAcconts,
  FetchCartsWITHTIMECustomAdmin,
  GETCARTSEARCH,
  SWAPPOSTPRODUCT,
} from "../API/Api";
import { toast } from "react-toastify";

////////////////////////////////////////////////  with backend working /////////////////////////////////////////////////////////////////////////////////////

export const CreateMenuItem = (Data, navigate) => async (dispatch) => {
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await createPost(Data);
      if (value.status === 201) {
        setTimeout(
          resolve(`status:${value.status}: Menu successfully added`),
          1000
        );
      }
      dispatch({ type: CREATE_USERMENUS, payload: value.data });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 409") {
        setTimeout(
          reject(
            `status:${error.status} Menu is not created by Controller issue`
          ),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Menu creating is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const EditMenuItem = (DATA, navigate) => async (dispatch) => {
  const id = DATA.id;
  const updatedpost = { name: DATA.name, img: DATA.img };

  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await UpdatePost(id, updatedpost);
      const data = value.data;

      if (value.status === 201) {
        setTimeout(
          resolve(`status:${value.status}: Menu successfully Edited`),
          1000
        );
      }
      dispatch({ type: UPDATE_MENU, payload: data });
      // navigate('/dashboard/home')
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(
          reject(
            `status:${error.status} Menu is not edited by Controller issue`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(`status:${error.status} Edited menu not find by id`),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Menu editing is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const FetchPOST = (navigate) => async (dispatch) => {
  const usermenu = JSON.parse(localStorage.getItem("usermenu"));
  const userproduct = JSON.parse(localStorage.getItem("userproduct"));
  const waitersAcc = JSON.parse(localStorage.getItem("waiteracc"));
  // console.log(usermenu);
  // console.log(userproduct);

  try {
    dispatch({ type: START_LOADING });
    const value = await fetchPosts();
    const data = value.data;
    const value1 = await fetchProducts();
    const data1 = value1.data;
    const value5 = await GetwaiterAcconts();
    const data5 = value5.data;
    console.log(data5);

    dispatch({ type: USERMENUS, payload: data });
    dispatch({ type: USERPRODUCTS, payload: data1 });
    dispatch({ type: WAITERS_ACCOUNTS, payload: data5 });
    dispatch({ type: END_LOADING });

    // if (usermenu === null && userproduct === null && waitersAcc === null ) {
    //     dispatch({ type: START_LOADING })
    //     const value = await fetchPosts();
    //     const data = value.data;
    //     const value1 = await fetchProducts();
    //     const data1 = value1.data;
    //     const value5 = await GetwaiterAcconts()
    //     const data5 = value5.data;
    //     console.log(data5);

    //     dispatch({ type: USERMENUS, payload: data })
    //     dispatch({ type: USERPRODUCTS, payload: data1 })
    //     dispatch({ type:WAITERS_ACCOUNTS , payload: data5 })
    //     dispatch({ type: END_LOADING })
    // } else if (usermenu != null &&  waitersAcc === null && userproduct === null) {
    //     dispatch({ type: START_LOADING })
    //     const value = await fetchPosts();
    //     const data = value.data;
    //     dispatch({ type: USERMENUS, payload: data })
    //     dispatch({ type: USERPRODUCTS, payload: userproduct })
    //     dispatch({ type: END_LOADING })
    // } else if (usermenu === null &&  waitersAcc === null && userproduct != null) {
    //     dispatch({ type: START_LOADING })
    //     const value1 = await fetchProducts();
    //     const data1 = value1.data;
    //     dispatch({ type: USERMENUS, payload: usermenu })
    //     dispatch({ type: USERPRODUCTS, payload: data1 })
    //     dispatch({ type: END_LOADING })
    // }else if (usermenu != null && userproduct != null && waitersAcc === null) {
    //     dispatch({ type: START_LOADING })
    //     const value5 = await GetwaiterAcconts()
    //     const data5 = value5.data;
    //     console.log(data5);
    //     dispatch({ type: USERMENUS, payload: usermenu })
    //     dispatch({ type: USERPRODUCTS, payload: userproduct })
    //     dispatch({ type:WAITERS_ACCOUNTS , payload: data5 })
    //     dispatch({ type: END_LOADING })
    // }else if (usermenu != null && userproduct != null && waitersAcc  != null) {
    //     dispatch({ type: START_LOADING })

    //     dispatch({ type: USERMENUS, payload: usermenu })
    //     dispatch({ type: USERPRODUCTS, payload: userproduct })
    //     dispatch({ type:WAITERS_ACCOUNTS , payload: waitersAcc })
    //     dispatch({ type: END_LOADING })
    // }else {
    //     dispatch({ type: START_LOADING })
    //     const value5 = await GetwaiterAcconts()
    //     const data5 = value5.data;
    //     console.log(data5);
    //     const value1 = await fetchProducts();
    //     const data1 = value1.data;
    //     dispatch({ type: USERMENUS, payload: usermenu })
    //     dispatch({ type: USERPRODUCTS, payload: data1 })
    //      dispatch({ type:WAITERS_ACCOUNTS , payload: data5 })
    //     dispatch({ type: END_LOADING })
    // }
    // dispatch({type : START_LOADING})
    // const value = await fetchPosts();
    // const data = value.data;
    // const value1 = await fetchProducts();
    // const data1 = value1.data;
    // dispatch({ type: USERMENUS, payload: data })
    // dispatch({ type: USERPRODUCTS, payload: data1 })
    // dispatch({type : END_LOADING})
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍 Menu Data is not Get Due to Some Ishue!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: 1,
      });
    } else if (error.message === "Request failed with status code 504") {
      toast.error("👍 token expire please login again!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: 1,
      });
      // navigate('/login');
      // navigate('/');
      // localStorage.clear();
    } else {
      toast.error("👍 Menu Data is not Get Due to Some Ishue!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: 1,
      });
    }
  }
};

// export const FetchPOST = () => async (dispatch) => {
//     // const resolveAfter3Sec = new Promise(async (resolve, reject) => {
//         // dispatch({type : START_LOADING})
//         try {
//             // dispatch({type : START_LOADING})
//             const value = await fetchPosts();
//             const data = value.data;

//             // if (value.status == 200) {
//             //     setTimeout(resolve(`status:${value.status}: Menu Data Successfully Get`), 1000)
//             // }
//             const value1 = await fetchProducts();
//             const data1 = value1.data;
//             // if (value.status == 200) {
//             //     setTimeout(resolve(`status:${value.status}: Product Data successfully GET`), 1000)
//             // }

//             dispatch({ type: USERMENUS, payload: data })
//             dispatch({ type: USERPRODUCTS, payload: data1 })
//             // dispatch({type : END_LOADING})

//         } catch (error) {

//             if (error.message == "Request failed with status code 402") {
//                 // setTimeout(reject("status:402 you have not access this task"), 1000)
//             } else if (error.message == "Request failed with status code 404") {
//                 // setTimeout(reject(`status:${error.status} Menu Data is not Get by Controller issue`), 1000)
//             }
//         }

//     // });

//     // toast.promise(resolveAfter3Sec, {
//     //     pending: "Menu Data geting is pending",
//     //     success: {
//     //         render({ data }) {
//     //             return `${data}`
//     //         }
//     //     },
//     //     error: {
//     //         render({ data }) {
//     //             return `${data}`
//     //         }
//     //     },
//     // })

// }

export const DELETEMENU = (id, DeletePro, navigate) => async (dispatch) => {
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const { data } = await DeleteProducts(id, DeletePro);
      const value = await Deletepost(id);
      if (value.status === 201) {
        setTimeout(
          resolve(
            `status:${value.status}: Menu and their products successfully deleted`
          ),
          1000
        );
      }
      dispatch({ type: DELETE_MENU, payload: id });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(
          reject(
            `status:${error.status} Menu is not created by Controller issue`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(`status:${error.status} Menu is not this post`),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Menu Deleting is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const MenuSettingSwiper = (swipeMenu) => async (dispatch) => {
  const id = swipeMenu.draggableId;
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await SWAPPOST(swipeMenu, id);
      const data = value.data;

      if (value.status === 202) {
        setTimeout(
          resolve(`status:${value.status}: Menu Swiping Successfully`),
          1000
        );
      }
      // SWAPPOST
      dispatch({ type: MENUSETTINGSWIPING, payload: data });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(
            `status:${error.status} Menu Data is not Swiping by Controller issue`
          ),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Menu Swiping is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const MenuSettingSwiperProduct = (swipeMenu) => async (dispatch) => {
  const id = swipeMenu.draggableId;
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await SWAPPOSTPRODUCT(swipeMenu, id);
      const data = value.data;

      if (value.status === 202) {
        setTimeout(
          resolve(`status:${value.status}: Product Swiping Successfully`),
          1000
        );
      }
      // SWAPPOST
      dispatch({ type: MENUPRODUCTSWIPING, payload: data });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(
            `Status: ${error.status} - Unable to swipe product data due to a controller issue.`
          ),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Menu Swiping is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const CreateProductItem = (Data, navigate) => async (dispatch) => {
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await createProduct(Data);
      const data = value.data;

      if (value.status === 200) {
        setTimeout(
          resolve(`status:${value.status}: Product successfully added`),
          1000
        );
      }
      dispatch({ type: CREATE_PRODUCT, payload: data });
      // navigate('/dashboard/home')
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(
            `status:${error.status} Product is not created by Controller issue`
          ),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Product Creating is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const FetchPRODUCT = () => async (dispatch) => {
  // const resolveAfter3Sec = new Promise(async (resolve, reject) => {
  try {
    dispatch({ type: START_LOADING });
    const value = await fetchProducts();
    const data = value.data;
    console.log(data);
    // if (value.status == 200) {
    //     setTimeout(resolve(`status:${value.status}: Product Data successfully GET`), 1000)
    // }

    dispatch({ type: USERPRODUCTS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // setTimeout(reject("status:402 you have not access this task"), 1000)
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍 Product data Data is not Get Due to Some Ishue!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: 1,
      });
      // setTimeout(reject(`status:${error.status} Product is not Geting by Controller issue`), 1000)
    }
  }

  // });

  // toast.promise(resolveAfter3Sec, {
  //     pending: "Product Geting is pending",
  //     success: {
  //         render({ data }) {
  //             return `${data}`
  //         }
  //     },
  //     error: {
  //         render({ data }) {
  //             return `${data}`
  //         }
  //     },
  // })
};

export const EditProductItem = (DATA, navigate) => async (dispatch) => {
  const id = DATA.id;
  const updatedpost = {
    name: DATA.name,
    img: DATA.img,
    price: DATA.price,
    menuname: DATA.menuname,
    menuid: DATA.menuid,
  };

  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await UpdateProduct(id, updatedpost);
      const data = value.data;

      if (value.status === 200) {
        setTimeout(
          resolve(`status:${value.status}: Product Edited successfully `),
          1000
        );
      }
      dispatch({ type: UPDATE_PRODUCT, payload: data });
      // navigate('/dashboard/home')
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(
            `status:${error.status} product is not created by Controller issue`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(
          reject(`status:${error.status} Product is not this id`),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Product Editing is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const DELETEPRODUCT = (id, navigate) => async (dispatch) => {
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await DeleteProduct(id);
      if (value.status === 200) {
        setTimeout(
          resolve(`status:${value.status}: Product Deleted successfully `),
          1000
        );
      }
      dispatch({ type: DELETE_PRODUCT, payload: id });
      // navigate('/dashboard/home')
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(
            `status:${error.status} product is not created by Controller issue`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(
          reject(`status:${error.status} Product is not this id`),
          1000
        );
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Product Deleting is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const itemCardQuantityIncrement = (Recipe) => async (dispatch) => {
  try {
    dispatch({ type: INCREMENT_CART, payload: Recipe });
  } catch (error) {}
};

export const itemCardQuantityDecrement = (Recipe) => async (dispatch) => {
  try {
    dispatch({ type: DECREMENT_CART, payload: Recipe });
  } catch (error) {}
};

export const itemCardQuantityIncrementCheckout =
  (Recipe) => async (dispatch) => {
    try {
      dispatch({ type: CHECKOUT_INCREMENT_CART, payload: Recipe });
    } catch (error) {}
  };

export const itemCardQuantityDecrementCheckout =
  (Recipe) => async (dispatch) => {
    try {
      dispatch({ type: DECREMENT_CART_CHECKOUT, payload: Recipe });
    } catch (error) {}
  };

export const itemCardQuantityDeleteCheckout = (Recipe) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CART_CHECKOUT, payload: Recipe });
    toast.error("👍 SUCCESS DELETES!", {
      position: "top-center",
      autoClose: 50,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  } catch (error) {}
};

export const ClearOrder = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_CHECKOUT });
  } catch (error) {}
};

//////////////CART////////////////////

export const BillOrders = (Order, setpopupOn, authData) => async (dispatch) => {
  console.log(Order);
  if (authData.result.posid === null) {
    const isOnline = navigator.onLine;
    if (!isOnline) {
      const offlineOrders =
        JSON.parse(localStorage.getItem("offlineOrders")) || [];
      offlineOrders.push(Order);
      localStorage.setItem("offlineOrders", JSON.stringify(offlineOrders));
      dispatch({ type: CREATE_CART_ORDERS_PRINT, payload: Order });
      setpopupOn(true);
      toast.info("Order queued. Will be processed when back online.", {
        position: "top-center",
        autoClose: 1000,
      });
      return;
    } else {
      try {
        const value = await CreateCart(Order);
        const data = value.data;
        dispatch({ type: CREATE_CART_ORDERS, payload: data.cart });
        dispatch({ type: UPDATE_PRODUCT_ITEMS, payload: data.product });
        dispatch({ type: CREATE_CART_ORDERS_PRINT, payload: data.cart });
        console.log(data.cart);
        setpopupOn(true);
        const offlineOrders =
          JSON.parse(localStorage.getItem("offlineOrders")) || [];
        if (offlineOrders.length > 0) {
          for (const queuedOrder of offlineOrders) {
            try {
              const queuedValue = await CreateCart(queuedOrder);
              console.log("Processed offline order:", queuedValue.data);
            } catch (error) {
              console.error("Failed to process offline order:", error.message);
              toast.error(
                `Failed to process queued order. Error: ${error.message}`,
                {
                  position: "top-center",
                  autoClose: 1000,
                }
              );
            }
          }
          localStorage.removeItem("offlineOrders");
          toast.success("Queued orders processed successfully.", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        dispatch({
          type: UPDATE_PRODUCT_ITEMS_FAIL,
          payload: Order.paymentItems,
        });
        if (error.message === "Request failed with status code 402") {
          toast.error("👍 status:402 you have not access this task", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (error.message === "Request failed with status code 409") {
          toast.error(
            `status:${error.status} Order is not Pleced you are putting some thing wrong`,
            {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    }
  } else {
    let saveValue = [];
    let saveCart = [];
    const OrderPay = Order.paymentItems.map((item) => {
      const textChar = item.TaxCharged + 16;
      const TaxP = (item.price * 16) / 100;
      const PricWithTax = item.price + TaxP;

      return {
        ...item,
        TaxRate: textChar,
        TaxCharged: TaxP,
        price: PricWithTax,
      };
    });
    Order = { ...Order, paymentItems: OrderPay };
    try {
      const valueX = await CreateCart(Order);
      const dataX = valueX.data;
      const CARTX = dataX.cart;
      saveValue.push(valueX);
      saveCart.push(CARTX);
      const PRADATA = {
        InvoiceNumber: "",

        POSID: authData.result.posid,
        USIN: dataX.cart._id,
        DateTime: dataX.cart.createdAt,
        BuyerPNTN: "1234567-8",
        BuyerCNIC: "12345-1234567-8",
        BuyerName: dataX.cart.CustmerName,
        BuyerPhoneNumber: "0000-0000000",
        TotalBillAmount: dataX.cart.FinalBill,
        TotalQuantity: dataX.cart.cartItems.reduce((currenttotal, item) => {
          return item.quantity + currenttotal;
        }, 0),
        TotalSaleValue: dataX.cart.totalBill,
        TotalTaxCharged: dataX.cart.TotalTaxCharged,
        TaxRate: 16,
        Discount: dataX.cart?.Discount ? dataX.cart?.Discount : 0,
        FurtherTax: 0.0,
        PaymentMode: 1,
        RefUSIN: null,
        InvoiceType: 1,
        ordernumber: dataX.cart.ordernumber,
        creator: dataX.cart.creator,
        totalBill: dataX.cart.totalBill,
        MergeCarts: dataX.cart.MergeCarts,
        Items: dataX.cart.cartItems.map((item) => {
          return {
            ...item,
            TaxRate: item.TaxRate,
            SaleValue: item.OneitemPrice,
            TotalAmount: item.price,
            TaxCharged: item.TaxCharged,
            InvoiceType: 1,
            ItemCode: item._id,
            PCTCode: item.PCTCode,
            ItemName: item.name,
          };
        }),
      };

      const dD = await OrderABI(PRADATA);
      const dDANS = dD.data;

      if (
        valueX.status === 200 &&
        dD.data.Errors == null &&
        dD.data.Code == 100
      ) {
        const value2 = await UpdateOrderByPraID(dDANS.InvoiceNumber, CARTX);

        if (value2.status === 200) {
          dispatch({ type: CREATE_CART_ORDERS, payload: value2.data });
          dispatch({ type: UPDATE_PRODUCT_ITEMS, payload: dataX.product });
          dispatch({ type: CREATE_CART_ORDERS_PRINT, payload: value2.data });
          setpopupOn(true);
        } else {
          toast.error(
            "status:402 order place successfully but id not store in DB",
            {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } else if (dD.data.Errors !== null) {
        const value4 = await DeleteOrderAndUpdateProduct(CARTX);
        dispatch({ type: UPDATE_PRODUCT_ITEMS, payload: value4.data.product });
        toast.error("status:402 order not place successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        dispatch({
          type: UPDATE_PRODUCT_ITEMS_FAIL,
          payload: Order.paymentItems,
        });
        toast.error("status:402 you have not access this task", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else if (error.message === "Request failed with status code 409") {
        dispatch({
          type: UPDATE_PRODUCT_ITEMS_FAIL,
          payload: Order.paymentItems,
        });
        toast.error(
          `status:${error.status} Order is not Pleced you are putting some thing wrong`,
          {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } else if (error.code === "ERR_NETWORK") {
        if (saveValue.length !== 0) {
          const CARTsaveCart = saveCart[0];
          const value4 = await DeleteOrderAndUpdateProduct(CARTsaveCart);
          dispatch({
            type: UPDATE_PRODUCT_ITEMS,
            payload: value4.data.product,
          });
          toast.error(`Order is not place PRA portal Network Error`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          dispatch({
            type: UPDATE_PRODUCT_ITEMS_FAIL,
            payload: Order.paymentItems,
          });
          toast.error(`Order is not place server Network Error`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        dispatch({
          type: UPDATE_PRODUCT_ITEMS_FAIL,
          payload: Order.paymentItems,
        });
        toast.error("Order is not placed", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  }
};

export const FetchOrders = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const value = await FetchCarts(page);
    const data = value.data;

    dispatch({ type: CART_ORDERS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Orders is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const FetchOrdersPagination = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const value = await FetchCarts(page);
    const data = value.data;

    // dispatch({ type: CART_ORDERS, payload: data })
    dispatch({ type: CART_ORDERS_PAGI_SUCCESS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Orders is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const FetchCartsSearch = (Searchdata) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const value = await GETCARTSEARCH(Searchdata);
    const data = value.data;
    dispatch({ type: CART_ORDERS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    dispatch({ type: END_LOADING });
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Orders is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error("👍  Orders is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};
export const fetchCartsSearchPaginations = (Searchdata) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const value = await GETCARTSEARCH(Searchdata);
    const data = value.data;
    dispatch({ type: CART_ORDERS_PAGI_SUCCESS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    dispatch({ type: END_LOADING });
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Orders is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error("👍  Orders is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const FetchOrderBySearch = (SearchQuery) => async (dispatch) => {
  // try {
  //     dispatch({type : START_LOADING})
  //     const value = await CartFinds(SearchQuery);
  //     const data = value.data;

  //     // dispatch({type:SEARCH_POST,payload:data})
  //     dispatch({type : END_LOADING})
  // } catch (error) {

  // }

  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await CartFinds(SearchQuery);
      const data = value.data;

      if (value.status === 200) {
        setTimeout(
          resolve(`status:${value.status}: Order Get Successfully`),
          1000
        );
      }

      dispatch({ type: SEARCH_CART_ORDER, payload: data });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(
          reject(
            `status:${error.status} order is not geting by Controller issue`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(reject(`status:${error.status} Order is not this id`), 1000);
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Orders Geting is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

// ////////////////////////////Deleting///////////////////////////////////////////
export const EditOrderOFOrderDetail = (Edit) => async (dispatch) => {
  try {
    Edit.navigate("/dashboard/home");
    dispatch({ type: EditOrder, payload: Edit.editOrder });
  } catch (error) {}
};
////////////////////////////////////////////////////////////////////////////////////

export const UpdateBillOrder = (order, setpopupOn) => async (dispatch) => {
  const id = order.id;
  const updatedcart = {
    CustmerName: order.CustmerName,
    cartItems: order.paymentItems,
    ItemCount: order.ItemCount,
    totalBill: order.totalBill,
    ordernumber: order.ordernumber,
    Discount: order.Discount,
    FinalBill: order.FinalBill,
    mergeNo: order.mergeNo,
    OrderReciverid: order.OrderReciverid,
    OrderReciverName: order.OrderReciverName,
  };
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await UpdateCart(id, updatedcart);
      const data = value.data;

      if (value.status === 200) {
        setTimeout(
          resolve(`status:${value.status}: Order update successfully`),
          1000
        );
      }
      // dispatch({ type: UPDATECARTORDER, payload: data })
      // dispatch({ type: CREATE_CART_ORDERS_PRINT, payload: data })

      dispatch({ type: UPDATECARTORDER, payload: data.updatedCart });
      dispatch({ type: SKT_CUSTOMCART_UPDATE, payload: data.updatedCart });
      dispatch({ type: UPDATE_PRODUCT_ITEMS, payload: data.product });
      dispatch({ type: CREATE_CART_ORDERS_PRINT, payload: data.updatedCart });
      setpopupOn(true);
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(
          reject(
            `status:${error.status} order is not updating you are putting some thing wrong`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(reject(`status:${error.status} Order is not this id`), 1000);
      }
    }
  });
  toast.promise(resolveAfter3Sec, {
    pending: "Orders updating is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const DELETEORDER = (id) => async (dispatch) => {
  const resolveAfter3Sec = new Promise(async (resolve, reject) => {
    try {
      const value = await DeleteOrder(id);

      if (value.status === 200) {
        setTimeout(
          resolve(`status:${value.status}: Order Deleted successfully`),
          1000
        );
      }
      dispatch({ type: DELETECARTORDER, payload: id });
      dispatch({ type: SKT_CUSTOMCART_DELETE, payload: id });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        setTimeout(reject("status:402 you have not access this task"), 1000);
      } else if (error.message === "Request failed with status code 408") {
        setTimeout(
          reject(
            `status:${error.status} order is not Deleting by Controller issue`
          ),
          1000
        );
      } else if (error.message === "Request failed with status code 404") {
        setTimeout(reject(`status:${error.status} Order is not this id`), 1000);
      }
    }
  });

  toast.promise(resolveAfter3Sec, {
    pending: "Orders Deleting is pending",
    success: {
      render({ data }) {
        return `${data}`;
      },
    },
    error: {
      render({ data }) {
        return `${data}`;
      },
    },
  });
};

export const GetDetailOfTime = (TIME) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING1 });
    const value = await FetchCartsWITHTIME(TIME);
    const data = value.data;
    const value1 = await FetchCartsWITHTimeMonth(TIME);
    const data1 = value1.data;
    const value2 = await FetchCartsWITHTimeYear(TIME);
    const data2 = value2.data;

    dispatch({ type: DAYCARTSDATA, payload: data });
    dispatch({ type: MONTHCARTSDATA, payload: data1 });
    dispatch({ type: YEARCARTSDATA, payload: data2 });
    dispatch({ type: END_LOADING1 });
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Data is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const GetDetailOfTimeCustom = (TIME) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING1 });
    const value = await FetchCartsWITHTIMECustom(TIME);
    const data = value.data;

    dispatch({ type: CUSTOMCARTSDATA, payload: data });
    dispatch({ type: END_LOADING1 });
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Data is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const GetDetailOfTimeCustomAdmin = (TIME) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING1 });
    const value = await FetchCartsWITHTIMECustomAdmin(TIME);
    const data = value.data;

    dispatch({ type: CUSTOMCARTSDATA, payload: data });
    dispatch({ type: END_LOADING1 });
  } catch (error) {
    if (error.message === "Request failed with status code 402") {
      toast.error("👍 you have not access this task!", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (error.message === "Request failed with status code 404") {
      toast.error("👍  Data is not Geting some issue please refresh", {
        position: "top-center",
        autoClose: 50,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const GetDetailOfAccountOrdersTimeCustom =
  (TIME) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING1_CUSTOM });
      const value = await FetchAccountCartsWITHTIMECustom(TIME);
      const data = value.data;

      console.log(data);
      dispatch({ type: CUSTOMCARTSACCOUNTDATA, payload: data });
      dispatch({ type: END_LOADING1_CUSTOM });
    } catch (error) {
      if (error.message === "Request failed with status code 402") {
        toast.error("👍 you have not access this task!", {
          position: "top-center",
          autoClose: 50,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else if (error.message === "Request failed with status code 404") {
        toast.error("👍  Data is not Geting some issue please refresh", {
          position: "top-center",
          autoClose: 50,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

// export const GetDetailOfTimeMonth = (TIME) => async (dispatch) => {
//     const resolveAfter3Sec = new Promise(async (resolve, reject) => {
//         try {

//             const value = await FetchCartsWITHTimeMonth(TIME);
//             const data = value.data;

//             if (value.status == 200) {
//                 setTimeout(resolve(`status:${value.status}: Orders Geting successfully`), 1000)
//             }

//         } catch (error) {

//             if (error.message == "Request failed with status code 402") {
//                 setTimeout(reject("status:402 you have not access this task"), 1000)
//             } else if (error.message == "Request failed with status code 404") {
//                 setTimeout(reject(`status:${error.status} Orders is not Geting by Controller issue`), 1000)
//             }
//         }
//     });

//     toast.promise(resolveAfter3Sec, {
//         pending: "Orders Geting is pending",
//         success: {
//             render({ data }) {
//                 return `${data}`
//             }
//         },
//         error: {
//             render({ data }) {
//                 return `${data}`
//             }
//         },
//     })

// }
