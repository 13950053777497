import {
  START_LOADING_Extra,
  END_LOADING_Extra,
  Extra_USERPRODUCTS,
  CREATE_Extra_PRODUCT,
  UPDATE_Extra_PRODUCT,
  DELETE_Extra_PRODUCT,
} from "./ReducerCaseType";

export const reducerfetchExtraproducts = (
  state = { isLoading: true, extraproducts: [] },
  action
) => {
  switch (action.type) {
    case START_LOADING_Extra: {
      return { ...state, isLoading: true };
    }
    case END_LOADING_Extra: {
      return { ...state, isLoading: false };
    }

    case Extra_USERPRODUCTS:
      return {
        ...state,
        extraproducts: action.payload,
      };

    case CREATE_Extra_PRODUCT:
      const d = state.extraproducts;

      return { ...state, extraproducts: [...d, action.payload] };

    case UPDATE_Extra_PRODUCT:
      return {
        ...state,
        extraproducts: state.extraproducts.map((extraproduct) =>
          extraproduct._id === action.payload._id
            ? action.payload
            : extraproduct
        ),
      };

    case DELETE_Extra_PRODUCT:
      return {
        ...state,
        extraproducts: state.extraproducts.filter(
          (extraproduct) => extraproduct._id !== action.payload
        ),
      };

    //   case INCREMENT_CART:
    //     return {
    //       ...state, products: state.products.map((items) => {
    //         if (items.menuid == action.payload.RecipeMenuid && items._id == action.payload.RecipeFull._id) {
    //           return { ...items, quantity: items.quantity + 1 }
    //         } else {
    //           return items;
    //         }
    //       })
    //     }

    //   case DECREMENT_CART:
    //     return {
    //       ...state, products: state.products.map((items) => {
    //         if (items.menuid == action.payload.RecipeMenuid && items._id == action.payload.RecipeFull._id) {
    //           return { ...items, quantity: items.quantity - (items.quantity > 0 ? 1 : 0) }
    //         } else {
    //           return items;
    //         }
    //       })
    //     }

    //   case CHECKOUT_INCREMENT_CART:

    //     return {
    //       ...state, products: state.products.map((items) => {
    //         if (items._id == action.payload.RecipeFull._id) {

    //           return { ...items, quantity: items.quantity + 1 };
    //         } else {
    //           return items;
    //         }
    //       })
    //     }

    //   case DECREMENT_CART_CHECKOUT:

    //     return {
    //       ...state, products: state.products.map((items) => {
    //         if (items._id == action.payload.RecipeFull._id) {

    //           return { ...items, quantity: items.quantity - (items.quantity > 0 ? 1 : 0) };
    //         } else {
    //           return items;
    //         }
    //       })
    //     }

    //   case EditOrder:
    //     return {
    //       ...state, Username: action.payload.CustmerName, Discount: action.payload.Discount, Billid: action.payload._id, products: state.products.map((items) => {

    //         let a = action.payload.cartItems.find(e => e._id == items._id)

    //         if (a != undefined) {
    //           return { ...items, quantity: a.quantity }
    //         } else if (a == undefined) {
    //           return items;
    //         }

    //       })
    //     }

    //   case DELETE_CART_CHECKOUT:

    //     return {
    //       ...state, products: state.products.map((items) => {
    //         if (items._id == action.payload.RecipeFull._id) {
    //           return { ...items, quantity: 0 };
    //         } else {
    //           return items;
    //         }
    //       })
    //     }

    //   case CLEAR_CHECKOUT:

    //     return {
    //       ...state, products: state.products.map((items) => {
    //         return { ...items, quantity: 0 }

    //       })
    //     }

    default:
      return state;
  }
};
