import {
  START_LOADING1,
  END_LOADING1,
  DAYCARTSDATA,
  MONTHCARTSDATA,
  YEARCARTSDATA,
  CUSTOMCARTSDATA,
  CUSTOMCARTSDATA_CLEAR,
  CUSTOMCARTSACCOUNTDATA,
  START_LOADING1_CUSTOM,
  END_LOADING1_CUSTOM,
  SKT_CUSTOMCART_ADD,
  SKT_CUSTOMCART_UPDATE,
  SKT_CUSTOMCART_DELETE,
} from "./ReducerCaseType";

export const DayDataCartsReducer = (
  state = { isLoading: true, daycarts: [] },
  action
) => {
  switch (action.type) {
    case START_LOADING1: {
      return { ...state, isLoading: true };
    }
    case END_LOADING1: {
      return { ...state, isLoading: false };
    }
    case DAYCARTSDATA:
      return {
        ...state,
        daycarts: action.payload,
      };
    default:
      return state;
  }
};

export const MonthDataCartsReducer = (
  state = { isLoading: true, monthcarts: [] },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //     return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //     return { ...state, isLoading: false }
    // }
    case MONTHCARTSDATA:
      return {
        ...state,
        monthcarts: action.payload,
      };
    default:
      return state;
  }
};

export const YearDataCartsReducer = (
  state = { isLoading: true, yearcarts: [] },
  action
) => {
  switch (action.type) {
    // case START_LOADING: {
    //     return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //     return { ...state, isLoading: false }
    // }
    case YEARCARTSDATA:
      return {
        ...state,
        yearcarts: action.payload,
      };
    default:
      return state;
  }
};

export const CustomDataCartsReducer = (
  state = { isLoading: true, SaleAndCarts: { sale: 0, carts: [] } },
  action
) => {
  switch (action.type) {
    case START_LOADING1: {
      return { ...state, isLoading: true };
    }
    case END_LOADING1: {
      return { ...state, isLoading: false };
    }
    case CUSTOMCARTSDATA:
      return {
        ...state,
        SaleAndCarts: {
          ...state.SaleAndCarts,
          sale: action.payload.TotalSale,
          carts: action.payload.carts,
        },
      };
    case SKT_CUSTOMCART_ADD:
      return {
        ...state,
        SaleAndCarts: {
          ...state.SaleAndCarts,
          sale: state.SaleAndCarts.sale + action.payload.FinalBill,
          carts: [...state.SaleAndCarts.carts, action.payload],
        },
      };
    case SKT_CUSTOMCART_UPDATE:
      const findOldCart = state.SaleAndCarts.carts.find(
        (item) => item._id === action.payload._id
      );
      const UpdatedCart = action.payload;
      const OldSaleMinuOldCartBill =
        state.SaleAndCarts.sale - findOldCart.FinalBill;
      const NewSale = OldSaleMinuOldCartBill + UpdatedCart.FinalBill;
      return {
        ...state,
        SaleAndCarts: {
          ...state.SaleAndCarts,
          sale: NewSale,
          carts: state.SaleAndCarts.carts.map((cart) =>
            cart._id === action.payload._id ? action.payload : cart
          ),
        },
      };
    case SKT_CUSTOMCART_DELETE:
      const findOldCartID = state.SaleAndCarts.carts.find(
        (item) => item._id === action.payload
      );
      const OldSaleMinuOldCartBillF =
        state.SaleAndCarts.sale - (findOldCartID ? findOldCartID.FinalBill : 0);
      return {
        ...state,
        SaleAndCarts: {
          ...state.SaleAndCarts,
          sale: OldSaleMinuOldCartBillF,
          carts: state.SaleAndCarts.carts.filter(
            (cart) => cart._id !== action.payload
          ),
        },
      };

    case CUSTOMCARTSDATA_CLEAR:
      return {
        ...state,
        isLoading: true,
        SaleAndCarts: { sale: 0, carts: [] },
      };

    default:
      return state;
  }
};

export const CustomAccountOrdersReducer = (
  state = { isLoading: true, AccountAndCarts: { sale: 0, carts: [] } },
  action
) => {
  switch (action.type) {
    case START_LOADING1_CUSTOM: {
      return { ...state, isLoading: true };
    }
    case END_LOADING1_CUSTOM: {
      return { ...state, isLoading: false };
    }
    case CUSTOMCARTSACCOUNTDATA:
      console.log({
        ...state,
        AccountAndCarts: {
          ...state.AccountAndCarts,
          sale: action.payload.TotalSale,
          carts: action.payload.carts,
        },
      });
      return {
        ...state,
        AccountAndCarts: {
          ...state.AccountAndCarts,
          sale: action.payload.TotalSale,
          carts: action.payload.carts,
        },
      };
    default:
      return state;
  }
};
