import React from "react";
import "./Loader.css";
import { RotatingLines } from "react-loader-spinner";
import loading from "./loading.svg";

export const Loader = () => {
  return (
    <div className="loader-size">
      <img src={loading} alt="" style={{ height: "100px" }} />
    </div>
  );
};
